<template>
	<div class="">
		<div class="btn-box" :class="{'dis':(checkState == 0  || timest>0 || isClaimed) &&  address}">
			<a-button type="primary" @click="addRewardLog" :loading="isLoading">
				{{statusName}}
			</a-button>
		</div>
		<modal v-model="isShow">
			<div class="">
				<Countdown :timespans="timest" @onClose="init"></Countdown>
			</div>
			<a-button style="width:50%; height: 44px;" type="primary" @click="isShow = false">
				OK
			</a-button>
			
		</modal>
	</div>

</template>

<script>
	import modal from '@/components/modal.vue'
	import Countdown from '@/components/faucetCard/Countdown.vue'
	import { verifyTweet, checkStatus, twRtAuth, checkAddr } from '@/utils/twitter.api.js';
	import { enable } from "@/web3utils/base/index.js";
	import { mapState } from 'vuex'
	export default {
		name: 'airdropBtn',
		components: { Countdown, modal },
		data() {
			return {
				isClaimed: false,
				isLoading: false,
				status: 0,
				routeQ: '',
				checkState: 0,
				timest: -1,
				isShow: false,
			}
		},
		computed: {
			...mapState(['address']),
			statusName() {
				if (!this.address) {
					return 'Connect wallet'
				}
				if (this.timest > 0) {
					return 'Coming soon'
				}

				if (this.checkState == 0) {
					return 'Not eligible for claim'
				}
				if (this.status < 3) {
					return 'Connect Social Media'
				}
				if (this.isClaimed) {
					return 'Claimed'
				}
				return 'Claim'


			},
		},
		watch: {
			address(newValue, oldValue) {
				this.init();
			}
		},
		mounted() {
			
			this.routeQ = this.$route.query.verify;
			this.init();

		},
		methods: {

			async init() {
				this.isShow = false;
				const { data: { status, timestamp } } = await checkAddr({
					addr: this.address,
				})
				this.checkState = Number(status) || 0;

				// //22号的下午三点
				let startTime = new Date("2024-1-22 15:00:00");
				// let startTime = new Date();
				//设置结束时间
				// let start = startTime.getTime(); // 结束秒数
				// this.timest = start - timestamp * 1000;


				if (this.checkState <= 0) return
				this.checkStatus();
				this.verifyTweet();

			},
			async checkStatus() {
				const { success, data: { status }, code } = await checkStatus({
					addr: this.address,
				})
				this.status = Number(status) || 0;
			},
			async verifyTweet() {
				const { success, data: { status, claimed }, code } = await verifyTweet();
				this.isClaimed = claimed | false
			},
			async addRewardLog() {
				if (this.isClaimed) return
				this.goLogin();
				if (this.timest > 0) {
					return this.isShow = true;
				}
				if (this.checkState == 0) {
					return
				}
				//绑定社交媒体
				if (this.status < 3) {
					this.$router.push('/Setting')
					return
				}
				//成功后打开弹窗  弹窗不再验证推特绑定状态  只验证转发状态 
				this.$emit("confirm");

			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					this.$message('Wallet not logged in')
					return;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.time-box {
		position: fixed;
		top: 50%;
		left: 50%;
		transition: all 1s;
		transform: translate(-50%, -50%);

		background: #0B0B0B;
		padding: 30px;
		border: 1px solid #33C4F2;
		border-radius: 10px;

		width: 420px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;



		.iconguanbi {
			position: absolute;
			right: 15px;
			top: 15px;
			font-size: 20px;

		}

		p {
			font-size: 16px;
			margin: 0;
		}

		.time {}


		.ant-btn-primary {
			width: 100%;
			height: 100%;
			font-size: 16px;
			color: #fff;
			font-weight: 400;
			padding: 7px 0;
			background: linear-gradient(to right, #203dfc, #33c4f2);
			border: none;
		}

		@screen md {
			width: 307px;

			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);



			.ant-btn-primary {
				padding: 8px 0;
				font-size: 11px;
			}

		}
	}

	.btn-box {
		padding-top: 64px;
		max-width: 800px;

		.ant-btn-primary {
			width: 100%;
			height: unset;

			line-height: unset;
			padding: 16px 0px;
			border-radius: 12px;

			font-size: 16px;
			color: #fff;
			font-weight: 400;
			background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
			border: none;



		}

		@screen md {
			padding-top: 20px;

			.ant-btn-primary {
				height: unset;
				line-height: unset;
				padding: 8px 40px;
				font-size: 14px;
			}

		}



	}

	.dis {
		.ant-btn {
			cursor: no-drop;
			background: rgba(36, 36, 36, 1) !important;
			color: rgba(90, 90, 90, 1);
		}


	}
</style>
<template>
	<div class="airdrop flex-between">
		<div class="airdrop-left">
			<h1 class="flex">
				<span>Claim Your </span>
				<span> $EON </span>
				<span>Airdrop</span>
			</h1>
			<h4>
				The early birds who obtained the Neural Navigator Medal before 1:30 PM (UTC+0) on 28th December, 2023
				for Faucet event on Galxe are qualified to claim the airdrop.
			</h4>
			<airdropBtn @confirm="confirm"></airdropBtn>
		</div>
		<div class="airdrop-right">
			<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/airdropRightBg.png" alt="" srcset="" />
		</div>
		<claimPopupBtn v-model="isShow"></claimPopupBtn>
	</div>
</template>

<script>
	import { twRtAuth } from '@/utils/twitter.api.js';
	import modal from '@/components/modal.vue'
	import airdropBtn from './airdropBtn.vue'
	import claimPopupBtn from './claimPopupBtn.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'airdrop',
		components: { modal, airdropBtn, claimPopupBtn },
		data() {
			return {
				isShow: false,
			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {
			confirm() {
				this.isShow = true;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.airdrop {
		min-height: 90vh;

		.airdrop-left {
			flex: 1;
			margin-right: 50px;

			h1 {
				flex-wrap: wrap;
				max-width: 740px;

				span {
					display: inline-block;
					font-size: 66px;
					font-weight: 800;
					line-height: 9 2px;
					letter-spacing: 0.01em;
					text-align: left;
					color: rgba(255, 255, 255, 1);
				}

				span:nth-of-type(2) {
					padding-left: 10px;
					background-image: -webkit-linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);

					-webkit-background-clip: text;

					-webkit-text-fill-color: transparent;
				}
			}

			h4 {
				max-width: 740px;
				padding-top: 35px;
				
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;
				color: rgba(255, 255, 255, 0.6);
			}

			.btn-box {
				padding-top: 64px;

				.ant-btn-primary {
					width: 320px;
					height: unset;

					line-height: unset;
					padding: 10px 0px;
					border-radius: 100px;

					font-size: 20px;
					color: #fff;
					font-weight: 400;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;

					@screen md {
						height: unset;
						line-height: unset;
						width: 200px;
						padding: 8px 40px;
						font-size: 14px;
					}

				}
			}
		}

		.airdrop-right {
			width: 660px;
			// background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/airdropRightBg.png') no-repeat;
			// background-size: 100% 100%;
		}

		.popup-box {
			.popup-left {
				width: 300px;
				height: 300px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.popup-right {
				flex: 1;
				padding-left: 40px;
				text-align: left;
				width: 490px;

				h1 {
					margin: 0;
					padding-top: 30px;
					
					font-size: 32px;
					font-weight: 700;
					line-height: 32px;
					letter-spacing: 0px;
					color: rgba(255, 255, 255, 1);
				}

				h4 {
					margin: 0;
					padding: 24px 0;
					
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;
					color: rgba(255, 255, 255, 0.6);
				}

				ul {
					li {
						.left {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;
							text-align: left;
							color: rgba(255, 255, 255, 0.6);

						}

						.right {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;
							color: #fff;
						}
					}

					li+li {
						padding-top: 16px;
					}
				}
			}
		}



		@screen md {
			flex-direction: column-reverse;

			.airdrop-left {
				padding-top: 30px;
				margin: 0;
				text-align: center;

				h1 {
					margin: 0 auto;
					display: block;
					width: 242px;
					justify-content: center;

					span {
						display: inline;
						font-size: 32px;
						line-height: unset;
					}

					span:nth-of-type(2) {
						padding: 0;
						padding-right: 15px;
					}
				}

				h4 {
					font-size: 12px;
					line-height: 20px;
					text-align: center;
				}
			}

			.airdrop-right {
				width: 280px;
			}

			.popup-box {
				&>div {
					display: flex;
					flex-direction: column !important;
				}

				.popup-left {
					width: 100px;
					height: 100px;
				}

				.popup-right {
					width: unset;
					padding-left: 0;
					text-align: center;

					h1 {
						font-size: 20px;
						line-height: 0;
					}

					h4 {
						padding: 20px 0;
						margin-bottom: 20px;
						font-size: 12px;
						line-height: 18px;

						border-bottom: 1px solid rgba(49, 45, 58, 1);
					}

					ul {
						li {
							.left {
								font-size: 14px;
							}

							.right {
								font-size: 14px;
							}
						}
					}
				}
			}

		}




	}
</style>
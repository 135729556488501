<template>
	<div class="Airdrop">
		<Header></Header>
		<div class="Airdrop-box md:px-12">
			<Breadcrumb :routes="routes"></Breadcrumb>
			<div class="AirdropCard-box ">
				<airdrop></airdrop>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import airdrop from '@/components/airdrop/airdrop.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Faucet',
		components: {
			Header,
			Breadcrumb,
			airdrop,
			Footer,
		},
		data() {
			return {
				isShow:false,
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},
					{
						path: '/Airdrop',
						breadcrumbName: 'Airdrop',
					},
				],
			}
		},
		computed: {
			...mapState(['isConnect', 'address']),
		},
		mounted() {
			// 0x09128f1081771c9ad474363ff9cf3845977e39a2&code=S0tCRkxkX1I3OGZWZ1VhZVJmZS1zMldXUWh1aUJ3Mk1JUU9hREhQVWFMQndoOjE3MDIwMzE3MjYxNDA6MTowOmFjOjE
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.Airdrop {
		position: relative;
		background-color: #000000;
		color: #ffffff;

		.Airdrop-box {
			padding: 14px 80px;
		}
		.AirdropCard-box {
			
		}
	}
</style>
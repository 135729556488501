<template>
	<modal v-model="isShow">
		<div class="popup-box ">
			<div class="flex-between">
				<div class="popup-left">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/airdropPopupLeft.png" alt="" srcset="" />
				</div>
				<div class="popup-right">
					<h1>100 $EON </h1>
					<h4>The early birds who obtained the Neural Navigator Medal at 1:30 PM (UTC+0)
						on 28th December for Faucet event on Galxe are qualified to claim the airdrop.</h4>
					<ul>
						<li class="flex-between">
							<span class="left">Mint Fee :</span>
							<span class="right">Free</span>
						</li>
						<li class="flex-between">
							<span class="left">Mint Date :</span>
							<span class="right">01/22/2024 - 01/26/2024</span>
						</li>
						<li class="flex-between">
							<span class="left">Wallet Address :</span>
							<span class="right">{{address | addressF}}</span>
						</li>
						<li class="flex-between">
							<span class="left">Retweet :</span>
							<dl class="right verified flex" v-if="verifyT">
								<a-icon style="font-size: 14px;color: rgba(0, 223, 128, 1);" type="check-circle" theme="filled" />
								<dd>verified</dd>
							</dl>

							<dl class="right Unverified flex" v-else>
								<a-icon style="font-size: 14px; color: rgba(255, 98, 82, 1);" type="close-circle" theme="filled" />
								<dd>Unverified</dd>
							</dl>
						</li>
					</ul>
				</div>
			</div>
			<div class="btn-box" :class="{'dis': isClaimed && address}">
				<a-button type="primary" @click="addRewardLog" :loading="isLoading">
					{{statusName}}
				</a-button>

			</div>
		</div>
	</modal>
</template>

<script>
	import { verifyTweet, checkStatus, twRtAuth, userInfo, rtRewardClaim } from '@/utils/twitter.api.js';
	import { enable } from "@/web3utils/base/index.js";
	import modal from '@/components/modal.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'claimPopupBtn',
		components: { modal },
		data() {
			return {
				isLoading: false,
				isShow: false,
				isClaimed: false,
				status: 0,
				verifyT: 0,
				routeQ: '',
				statusOb: {
					0: 'Verify',
					1: 'Retweet',
					2: 'Not eligible for claim',
					3: 'Retweet',
				},
			}
		},
		props: {
			value: {
				type: Boolean,
				default: false
			},
		},
		filters: {
			addressF(v) {
				if (!v) {
					return "-";
				}
				return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, "$1…$2");
			},
		},
		watch: {
			address(newValue, oldValue) {
				this.init();
			},
			value(val) {
				this.isShow = val;
				if (val) {
					this.init();
				}
			},
			isShow(val) {
				this.$emit('input', val)
			}
		},
		computed: {
			...mapState(['address']),
			statusName() {
				if (!this.address) {
					return 'Connect wallet'
				}
				// if (this.status < 3) {
				// 	return 'Connect Social Media'
				// }
				if (this.verifyT == 0) {
					return 'Retweet'
				}

				if (this.isClaimed) {
					return 'Claimed'
				}
				// //社媒绑定状态=3  且路由返回rt-ok 验证转发状态
				// if (this.status == 3) {
				// 	return 'Verify'
				// }

				return 'Claim'
			},
		},
		methods: {
			async init() {
				this.routeQ = this.$route.query.verify;
				const { success, data: { status, claimed }, code } = await verifyTweet();
				this.verifyT =  status || 0
				// const isRetweet = localStorage.getItem('isRetweet')
				// this.verifyT = isRetweet ? status : 0
				this.isClaimed = claimed | false
			},
			// async checkStatus() {
			// 	const { success, data: { status }, code } = await checkStatus({
			// 		addr: this.address,
			// 	})
			// 	this.status = Number(status) || 0;
			// },
			async addRewardLog() {
				this.isLoading = true;
				if (this.isClaimed) {
					this.isLoading = false;
					return this.$message.warning('Awarded');
				}
				this.goLogin();
				if (this.verifyT == 0) {
					this.isLoading = true;
					const res = await twRtAuth();
					this.isLoading = false;
					window.open(res.data.url);
					// localStorage.setItem('isRetweet', true)
					// window.open('https://twitter.com/wod_global/status/1749312752198566228');
					return
				}
				//领取
				setTimeout(async () => {
					const { data: { status } } = await rtRewardClaim({flag: 'rt1'})
					this.isLoading = false;
					if (status == 1) {
						this.$message.success('Claim Successful');
						this.init()
					}

				}, 500)




			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					this.$message('Wallet not logged in')
					return;
				}
			},


		}
	}
</script>

<style lang="scss" scoped>
	.popup-box {
		.popup-left {
			width: 300px;
			height: 300px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.popup-right {
			flex: 1;
			padding-left: 40px;
			text-align: left;
			width: 490px;

			h1 {
				margin: 0;
				padding-top: 30px;
				
				font-size: 32px;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: 0px;
				color: rgba(255, 255, 255, 1);
			}

			h4 {
				margin: 0;
				padding: 24px 0;
				
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0px;
				color: rgba(255, 255, 255, 0.6);
			}

			ul {
				li {
					.left {
						
						font-size: 16px;
						font-weight: 400;
						line-height: 16px;
						letter-spacing: 0px;
						text-align: left;
						color: rgba(255, 255, 255, 0.6);

					}

					.right {
						
						font-size: 16px;
						font-weight: 400;
						line-height: 16px;
						letter-spacing: 0px;
						color: #fff;
						margin: 0;
						dd {
							padding-left: 5px;
							margin: 0;
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;
							color: #fff;
						}
					}

					.verified {
						dd {
							color: rgba(0, 223, 128, 1);
						}
					}

					.Unverified {
						dd {
							color: rgba(255, 98, 82, 1);
						}
					}
				}

				li+li {
					padding-top: 16px;
				}
			}
		}

		@screen md {
			&>div {
				display: flex;
				flex-direction: column !important;
			}

			.popup-left {
				width: 100px;
				height: 100px;
			}

			.popup-right {
				width: unset;
				padding-left: 0;
				text-align: center;

				h1 {
					font-size: 20px;
					line-height: 0;
				}

				h4 {
					padding: 20px 0;
					margin-bottom: 20px;
					font-size: 12px;
					line-height: 18px;

					border-bottom: 1px solid rgba(49, 45, 58, 1);
				}

				ul {
					li {
						.left {
							font-size: 14px;
						}

						.right {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.btn-box {
		padding-top: 34px;

		.ant-btn-primary {
			width: 100%;
			height: unset;

			line-height: unset;
			padding: 16px 0px;
			border-radius: 12px;

			font-size: 16px;
			color: #fff;
			font-weight: 400;
			background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
			border: none;



		}

		@screen md {
			padding-top: 20px;

			.ant-btn-primary {
				height: unset;
				line-height: unset;
				padding: 8px 40px;
				font-size: 14px;
			}

		}



	}

	.dis {
		.ant-btn {
			background: rgba(36, 36, 36, 1) !important;
			color: rgba(90, 90, 90, 1);
		}
	}
</style>